import React from 'react'

import Layout from '../../../components/layout'
import SEO from "../../../components/seo"
// import { Helmet } from 'react-helmet'
import TwoColumn from "../../../components/two-column"
import CustomerJourney from '../../../components/customer-journey'

const oneUpBilling = {
  title: "Be as generous as Oprah with flexible discounts and coupons.",
  description: [
    {
      text: "Advanced billing options from Billsby let you collect more recurring revenue, introduce business rules and logic and offer more flexible plans to your customers - all with no or limited software development."
    }
  ],
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up today'
    },
    {
      scheduleButton: true
    }
  ],
  sectionDevelopers: true,
  image: require('../../../images/discount-coupons.svg'),
  srcSet: 'discount-coupons.svg 2x',
  imageName: 'discount-coupons svg',
  backgroundColor: '#F3F5FB',
  textColor: '#252422',
  imagePosition: 'right',
  svg: true
}

const freeTrials = {
  title: "First, create a library of discounts that work across your plans.",
  list: [
    {
      title: 'Percentage and flat discounts ',
      description: <>Discount your services by a percentage - like 25% off - or an amount - like $20 off. We'll make sure your discount never drops below zero.</>,
    },
    {
      title: 'Decide what your discount applies to',
      description: <>Stay compliant and do the right thing with automatic reminder emails. Got a different strategy? Turn them off if you'd like.</>,
    },
    {
      title: 'Choose what components your discount impacts ',
      description: <>Have discounts impact the total invoice value - including add-ons and allowances or, if you prefer, just the base plan.</>,
    }
  ],
  image: require('../../../images/library.svg'),
  imageName: 'library',
  svg: true
}

const setupFees = {
  title: "Unlock and apply discounts with the Billsby API.",
  description: [
    {
      text: "Once you've created your discounts you can unlock them using advanced business logic with our API and apply them to accounts. This allows for creative billing logic - like loyalty discounts, affinity schemes and more - just create your own criteria logic and then load up the discount seamlessly in Billsby - applying and removing discounts whenever you need to. It's completely customizable discount logic without the headaches."
    }
  ],
  imagePosition: 'full-right',
  textColor: '#fff',
  backgroundColor: '#243f5c',
  backgroundImage: require('../../../images/apply-dscounts.png'),
  image: 'apply-dscounts.png',
  imageName: 'apply discounts',
  backgroundPosition: 'right',
  sectionProtection: true,
}

const enchanceMMR = {
  title: "Next, promote your discounts with coupon codes.",
  list: [
    {
      title: 'Create and download batches of single-use coupon codes ',
      description: <>Need unique codes for flyers or newsletters? No problem. Just tell us how many to generate and we'll instantly create a batch for you.</>,
    },
    {
      title: 'Set multi-use coupons for social media and shared promotions',
      description: <>Or create a discount code to rule them all with multi-use codes - with use limits set to ensure promotional costs are controlled.</>,
    },
    {
      title: 'Determine promotion rules',
      description: <>Set expiry dates for your coupons, and manually end promotions early if you need to - you're always in control of your coupon codes.</>,
    }
  ],
  image: require('../../../images/big-savings.svg'),
  imageName: 'Big Savings',
  align: 'right',
  svg: true
}

const CouponDiscounts = () => {
  return (
    <Layout className="coupons-discounts">
      <SEO 
        title="Discounts & coupons with advanced billing services | Billsby" 
        description="Create discounts & coupons for your business offers and promotions with the best subscription billing software | Billsby" 
        url="https://www.billsby.com/product/creating/coupon-discounts"
      />

      <TwoColumn content={oneUpBilling}/>
      <CustomerJourney content={freeTrials} />
      <TwoColumn content={setupFees}/>
      <CustomerJourney content={enchanceMMR} />
    </Layout>
  )
}

export default CouponDiscounts